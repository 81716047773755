import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
import { LinkArrowSimple, Intro } from '~/src/components/Typography';
export const frontmatter = {
  title: "Uploadcare's Awards and Achievements",
  header: "Uploadcare's Awards and Achievements: A Milestone Overview",
  description: "At Uploadcare, we are committed to advancing, pioneering, and acknowledging achievements. Let's take a glimpse at our strides in the realms of cloud technology and SaaS. It's not just about accolades; it's about the path we've walked and the influence we've had."
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <style>
  {`
    img {
      margin: 0 !important;
      height: auto;
      width: 100px;
    }

    .round {
      width: 80px;
    }

    .square {
      width: 70px;
    }

    .illustrated {
      display: grid;
      grid-template-columns: 1fr 7fr;
      grid-gap: 25px;
      align-items: center;
    }

    @media (max-width: 768px) {
      .illustrated {
        grid-gap: 0;
        display: flex;
        flex-direction: column;
      }
    }
  `}
    </style>
    <Intro mdxType="Intro">
  At Uploadcare, we believe in progress, innovation, and recognition. Here’s a look at what we’ve
  accomplished in the cloud technology and SaaS sectors. It’s more than just awards; it’s about the
  journey and the impact.
    </Intro>
    <hr></hr>
    <h2 {...{
      "id": "our-recognitions"
    }}><a parentName="h2" {...{
        "href": "#our-recognitions"
      }}>{`Our Recognitions:`}</a></h2>
    <h3 {...{
      "id": "2020-2021-cloud-award-for-best-b2b-customer-strategy"
    }}><a parentName="h3" {...{
        "href": "#2020-2021-cloud-award-for-best-b2b-customer-strategy"
      }}>{`2020-2021 Cloud Award for Best B2B Customer Strategy`}</a></h3>
    <div className="illustrated">
  <img alt='Cloud Awards badge' src="https://ucarecdn.com/3bea025d-ce4a-4812-bf76-85bc486638b0/-/preview/300x300/-/quality/smart/-/format/auto/" />
      <p>{`Customer experience is our north star. Winning this award validates our commitment to you, our
clients, in the B2B space. It's about solution-driven strategies.`}</p>
    </div>
    <h3 {...{
      "id": "finalist-at-the-devops-dozen-awards-2020"
    }}><a parentName="h3" {...{
        "href": "#finalist-at-the-devops-dozen-awards-2020"
      }}>{`Finalist at the DevOps Dozen Awards 2020`}</a></h3>
    <div className="illustrated">
  <img className="round" alt='DevOps Dozen 2020 badge' src="https://ucarecdn.com/688bd242-bf1b-4589-9457-ab7f3f8eac10/-/preview/300x300/-/quality/smart/-/format/auto/" />
      <p>{`Efficiency meets collaboration. Being a finalist here speaks volumes about our dedication to
software development and operations.`}</p>
    </div>
    <h3 {...{
      "id": "shortlisted-at-the-cloud-awards"
    }}><a parentName="h3" {...{
        "href": "#shortlisted-at-the-cloud-awards"
      }}>{`Shortlisted at The Cloud Awards`}</a></h3>
    <div className="illustrated">
  <img alt='Cloud Awards badge' src="https://ucarecdn.com/3bea025d-ce4a-4812-bf76-85bc486638b0/-/preview/300x300/-/quality/smart/-/format/auto/" />
      <p>{`In the realm of cloud computing, being shortlisted at the Cloud Awards means we're on the right
track. It's about leveraging the cloud for greater possibilities.`}</p>
    </div>
    <h3 {...{
      "id": "top-5-fastest-growing-saas-companies"
    }}><a parentName="h3" {...{
        "href": "#top-5-fastest-growing-saas-companies"
      }}>{`Top 5 Fastest Growing SaaS Companies`}</a></h3>
    <div className="illustrated">
  <img className="square" alt='Growth icon' src="https://ucarecdn.com/6d563b5e-bf66-4cc8-acce-d69d38016280/-/preview/300x300/-/quality/smart/-/format/auto/" />
      <p>{`Growth in the SaaS industry isn't just about numbers; it's about sustainable innovation. Being in
the top 5? That's progress in action.`}</p>
    </div>
    <h3 {...{
      "id": "among-the-10-finalists-of-the-kryptonite-startup-challenge-2020"
    }}><a parentName="h3" {...{
        "href": "#among-the-10-finalists-of-the-kryptonite-startup-challenge-2020"
      }}>{`Among the 10 Finalists of the Kryptonite Startup Challenge 2020`}</a></h3>
    <div className="illustrated">
  <img className="square" alt='Kryptonite Startup Challenge 2020 logo' src="https://ucarecdn.com/b6729c35-e973-420d-acae-bb9985f2de1b/-/preview/300x300/-/quality/smart/-/format/auto/" />
      <p>{`Startups are the heart of innovation. Our presence among the top 10 finalists is proof of our
ongoing drive to break new ground.`}</p>
    </div>
    <hr></hr>
    <h2 {...{
      "id": "this-is-just-the-beginning"
    }}><a parentName="h2" {...{
        "href": "#this-is-just-the-beginning"
      }}>{`This is just the beginning`}</a></h2>
    <p>{`We're committed to a continuous journey of growth, excellence, and innovation. This isn't the end;
it's just the beginning. Stay connected with us as we forge ahead.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      